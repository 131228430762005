import { Produto } from "./produto";

export class FinanciamentoProduto {
  public id!: number | string | null;
  public tipoProposta!: string;
  public formalizacao!: string;
  public produto!: Produto;
  public percentMinimoLiberado!: number;
  public valorMinimoLiberado!: number;
  public qtdParcelasPagas!: number;
}