import { UUID } from "crypto";
import { Usuario } from "./usuario";

export class OrigemUsuario {
  public id!: UUID;
  public usuario!: Usuario;
  public idOrigem!: number;
  public nomeOrigem!: string;

  public idsMatrizes!: number[];
}