export class CalculoResultadoSimulacaoDTO {
  public promotora!: string;
  public empregador!: string;
  public financiamento!: string;
  public produto!: string;
  public valorRMC!: number;
  
  public valorLimite!: number;
  public limiteCompra!: number;
  public limiteSaque!: number;
  public tarifaEmissao!: number;
  public anuidade!: number;
  
  public dtNascimentoCliente!: Date;

  public prazos!: number[];
}