import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';
import bpoimg from '../resources/logo/bpo.png';
import { faBook, faList, faUser, faPrint, faBuilding, faListCheck, faComment, faSitemap, faCog, faBuildingColumns, faMagnifyingGlass, faMinusCircle, faMoneyBill, faTable, faTrash, faUndo, faArchive, faShareSquare, faUndoAlt, faCalculator, faUserCircle, faRuble, faArrowAltCircleUp, faTasksAlt, faFile, faStepBackward, faTimesCircle, faListOl, faHistory, faCheck, faComments, faCommenting, faCommentSms, faUsers, faSignOut } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Offcanvas, Row } from 'react-bootstrap';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function NavBar() {
  const navigate = useNavigate();
  const nomeUsuario = localStorage.getItem('nome');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div style={{ width: '100%' }}>
      <div className="row align-items-center">
        <Navbar expand="lg" className="row align-items-center">
          <Row>
            {/* MENU LATERAL */}
            <Container style={{ marginLeft: '30px', display: 'flex', justifyContent: 'space-between' }}>
              <Offcanvas show={show} onHide={handleClose} placement="start">
                <Offcanvas.Header closeButton>
                  <Navbar.Brand href="/home">
                    <Image width={"130px"} src={bpoimg} fluid />
                  </Navbar.Brand>
                </Offcanvas.Header>
                <Offcanvas.Title style={{ display: 'flex', justifyContent: 'center' }}>{nomeUsuario && <span style={{ marginLeft: '19px', fontWeight: 'bold', alignItems: 'center' }}>Olá, {nomeUsuario}</span>}</Offcanvas.Title>
                <Nav.Item style={{ display: 'flex', justifyContent: 'center', fontSize: '12px' }}>
                  <Nav.Link onClick={() => window.location.href = '/alterar-senha'} style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>
                    <FontAwesomeIcon icon={faCog} className="animated-icon" style={{ marginRight: '5px' }} />
                    Alterar senha
                  </Nav.Link>
                </Nav.Item>

                <Offcanvas.Body>

                  {/* PROPOSTA */}
                  <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
                    <FontAwesomeIcon icon={faBook} style={{ marginTop: '5px', }} />
                    <NavDropdown title="Simulação" style={{ marginLeft: '11px' }}>
                      <NavDropdown.Item href="/proposta-cartao">
                        <FontAwesomeIcon icon={faCalculator} style={{ marginRight: '5px' }} />Cartão
                      </NavDropdown.Item>
                    </NavDropdown>
                  </div>

                  {/* ESTEIRA */}
                  <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
                    <FontAwesomeIcon icon={faList} style={{ marginTop: '5px' }} />
                    <NavDropdown title="Esteira" style={{ marginLeft: '11px' }}>
                      <NavDropdown.Item href="/esteira-proposta/FLUXO">
                        <FontAwesomeIcon icon={faList} style={{ marginRight: '5px' }} />Propostas
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/esteira-proposta/LOTE">
                        <FontAwesomeIcon icon={faTasksAlt} style={{ marginRight: '5px' }} />Processamento em Lote
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/esteira-consulta-documentos">
                        <FontAwesomeIcon icon={faFile} style={{ marginRight: '5px' }} />Consulta Documentos
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/esteira-retorno-fluxo">
                        <FontAwesomeIcon icon={faStepBackward} style={{ marginRight: '5px' }} />Retorno Fluxo
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/esteira-reprovar-proposta">
                        <FontAwesomeIcon icon={faTimesCircle} style={{ marginRight: '5px' }} />Reprovar Proposta
                      </NavDropdown.Item>
                    </NavDropdown>
                  </div>

                  {/* CLIENTE */}
                  <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
                    <FontAwesomeIcon icon={faUser} style={{ marginTop: '5px' }} />
                    <NavDropdown title="Clientes" style={{ marginLeft: '11px' }}>
                      <NavDropdown.Item href="/cliente-consulta/LIMITE">
                        <FontAwesomeIcon icon={faMagnifyingGlass} style={{ marginRight: '5px' }} />Consulta Limite
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/cliente-consulta/ATUALIZACAO">
                        <FontAwesomeIcon icon={faUserCircle} style={{ marginRight: '5px' }} />Atualização Cadastral
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/cliente-consulta-historico">
                        <FontAwesomeIcon icon={faHistory} style={{ marginRight: '5px' }} />Consulta Histórico
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/cliente-aprovar-atualizacao">
                        <FontAwesomeIcon icon={faCheck} style={{ marginRight: '5px' }} />Aprovar Atualização
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/cliente-relatorio">
                        <FontAwesomeIcon icon={faList} style={{ marginRight: '5px' }} />Relatório de Clientes
                      </NavDropdown.Item>
                    </NavDropdown>
                  </div>

                  {/* RELATORIOS */}
                  <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
                    <FontAwesomeIcon icon={faPrint} style={{ marginTop: '5px' }} />
                    <NavDropdown title="Relatórios" style={{ marginLeft: '11px' }}>
                      <NavDropdown.Item href="/relatorio-analitico">
                        <FontAwesomeIcon icon={faListOl} style={{ marginRight: '5px' }} />Produção Analítico
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/relatorio-sintetico">
                        <FontAwesomeIcon icon={faListOl} style={{ marginRight: '5px' }} />Produção Sistético
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/relatorio-origens-cadastradas">
                        <FontAwesomeIcon icon={faList} style={{ marginRight: '5px' }} />Origens Cadastradas
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/relatorio-extrato-mensal">
                        <FontAwesomeIcon icon={faListOl} style={{ marginRight: '5px' }} />Extrato Mensal
                      </NavDropdown.Item>
                    </NavDropdown>
                  </div>

                  {/* ORIGENS */}
                  <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
                    <FontAwesomeIcon icon={faBuilding} style={{ marginTop: '5px' }} />
                    <NavDropdown title="Origens" style={{ marginLeft: '11px' }}>
                      <NavDropdown.Item href="/origens-matriz">
                        <FontAwesomeIcon icon={faBuilding} style={{ marginRight: '5px' }} />Matriz
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/origens-promotora">
                        <FontAwesomeIcon icon={faBuilding} style={{ marginRight: '5px' }} />Promotora
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/origens-supervisor">
                        <FontAwesomeIcon icon={faUserCircle} style={{ marginRight: '5px' }} />Supervisor
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/origens-operador">
                        <FontAwesomeIcon icon={faUserCircle} style={{ marginRight: '5px' }} />Operador
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/origens-ponto-venda">
                        <FontAwesomeIcon icon={faBuilding} style={{ marginRight: '5px' }} />Ponto de Venda
                      </NavDropdown.Item>
                    </NavDropdown>
                  </div>

                  {/* PARAMETROS CARTAO */}
                  <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
                    <FontAwesomeIcon icon={faListCheck} style={{ marginTop: '5px' }} />
                    <NavDropdown title="Parâmetros Cartão" style={{ marginLeft: '11px' }}>
                      <NavDropdown.Item href="/parametros-esteira">
                        <FontAwesomeIcon icon={faList} style={{ marginRight: '5px' }} />Esteiras
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/parametros-produto">
                        <FontAwesomeIcon icon={faRuble} style={{ marginRight: '5px' }} />Produtos
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/parametros-empregador">
                        <FontAwesomeIcon icon={faBuilding} style={{ marginRight: '5px' }} />Empregadores
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/parametros-financiamento">
                        <FontAwesomeIcon icon={faCalculator} style={{ marginRight: '5px' }} />Tabela de Financiamento
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/motivo-recusa">
                        <FontAwesomeIcon icon={faList} style={{ marginRight: '5px' }} />Motivos Recusa/Pendência
                      </NavDropdown.Item>
                    </NavDropdown>
                  </div>

                  {/* COMUNICADOS */}
                  <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
                    <FontAwesomeIcon icon={faComment} style={{ marginTop: '5px' }} />
                    <NavDropdown title="Comunicados" style={{ marginLeft: '11px' }}>
                      <NavDropdown.Item href="/comunicados-vigentes">
                        <FontAwesomeIcon icon={faComment} style={{ marginRight: '5px' }} />Comunicados Vigentes
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/comunicados-tipos">
                        <FontAwesomeIcon icon={faCommenting} style={{ marginRight: '5px' }} />Tipos de Comunicados
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/comunicados">
                        <FontAwesomeIcon icon={faComments} style={{ marginRight: '5px' }} />Comunicados
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/comunicados-confirmacao">
                        <FontAwesomeIcon icon={faCommentSms} style={{ marginRight: '5px' }} />Confirmação de Comunicados
                      </NavDropdown.Item>
                    </NavDropdown>
                  </div>

                  {/* SISTEMA */}
                  <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
                    <FontAwesomeIcon icon={faSitemap} style={{ marginTop: '5px' }} />
                    <NavDropdown title="Sistema" style={{ marginLeft: '11px' }}>
                      <NavDropdown.Item href="/sistema-parametros-ti">
                        <FontAwesomeIcon icon={faCog} style={{ marginTop: '5px', marginRight: '5px' }} />Parâmetros TI
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/sistema-usuario">
                        <FontAwesomeIcon icon={faUsers} style={{ marginTop: '5px', marginRight: '5px' }} />Usuários
                      </NavDropdown.Item>
                    </NavDropdown>
                  </div>

                  {/* BACKOFFICE */}
                  <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
                    <FontAwesomeIcon icon={faBuildingColumns} style={{ marginTop: '5px' }} />
                    <NavDropdown title="BackOffice" style={{ marginLeft: '11px' }}>
                      <NavDropdown.Item href="/backoffice-contrato/consulta">
                        <FontAwesomeIcon icon={faMagnifyingGlass} style={{ marginRight: '3px', marginLeft: '-13px' }} />Consulta de Contrato
                      </NavDropdown.Item>
                      <NavDropdown title={<span><FontAwesomeIcon icon={faSitemap} style={{ marginTop: '5px', marginRight: '5px' }} />Lançamentos</span>} id="contrato-dropdown">
                        <NavDropdown.Item href="/backoffice-lancamentos/baixa-parcela/">
                          <FontAwesomeIcon icon={faMoneyBill} style={{ marginRight: '5px' }} />Baixa de Parcelas - Manual
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/backoffice-contrato/prorrogacao-vencimento">
                          <FontAwesomeIcon icon={faMinusCircle} style={{ marginRight: '5px' }} />Prorrogação de Vencimentos - Manual
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/backoffice-arquivo-lote/LANCAMENTO">
                          <FontAwesomeIcon icon={faArchive} style={{ marginRight: '5px' }} />Processos Em Lote
                        </NavDropdown.Item>

                        {/* BACKOFFICE - LANCAMENTOS */}
                        <NavDropdown.Item href="/backoffice-lancamentos/estorno-parcela">
                          <FontAwesomeIcon icon={faMinusCircle} style={{ marginRight: '5px' }} />Estorno de Parcela
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/backoffice-lancamentos/cancelamento-movimentacao">
                          <FontAwesomeIcon icon={faTrash} style={{ marginRight: '5px' }} />Cancelamento de Movimentação
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/backoffice-lancamentos/estorno-operacao">
                          <FontAwesomeIcon icon={faUndo} style={{ marginRight: '5px' }} />Estorno de Operação
                        </NavDropdown.Item>
                      </NavDropdown>

                      {/* BACKOFFICE - CESSAO */}
                      <NavDropdown title={<span><FontAwesomeIcon icon={faMoneyBill} style={{ marginTop: '5px', marginRight: '5px' }} />Cessão</span>} id="contrato-dropdown">
                        <NavDropdown.Item href="/backoffice-cessao/lancamento-manual">
                          <FontAwesomeIcon icon={faShareSquare} style={{ marginRight: '5px' }} />Lançamento Manual
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/backoffice-cessao/recompra-manual">
                          <FontAwesomeIcon icon={faUndoAlt} style={{ marginRight: '5px' }} />Recompra Manual
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/backoffice-arquivo-lote/CESSAO">
                          <FontAwesomeIcon icon={faArchive} style={{ marginRight: '5px' }} />Processos Em Lote
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/backoffice-cessao/remessa-liquidacao">
                          <FontAwesomeIcon icon={faArrowAltCircleUp} style={{ marginRight: '5px' }} />Remessa Liquidação
                        </NavDropdown.Item>
                      </NavDropdown>

                      {/* BACKOFFICE -RELATORIOS */}
                      <NavDropdown title={<span><FontAwesomeIcon icon={faList} style={{ marginTop: '5px', marginRight: '5px' }} />Relatórios</span>} id="contrato-dropdown">
                        <NavDropdown.Item href="/backoffice-relatorios/operacoes-realizadas/">
                          <FontAwesomeIcon icon={faList} style={{ marginRight: '5px' }} />Operações Realizadas
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/backoffice-relatorios/vencimentos">
                          <FontAwesomeIcon icon={faList} style={{ marginRight: '5px' }} />De Vencimentos
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/backoffice-relatorios/movimentacao-financeira">
                          <FontAwesomeIcon icon={faList} style={{ marginRight: '5px' }} />Movimentação Financeira
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/backoffice-relatorios/contratos-cedidos">
                          <FontAwesomeIcon icon={faList} style={{ marginRight: '5px' }} />Contratos Cedidos
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/backoffice-relatorios/parcelas-cedidas">
                          <FontAwesomeIcon icon={faList} style={{ marginRight: '5px' }} />Parcelas Cedidas
                        </NavDropdown.Item>
                      </NavDropdown>

                      {/* BACKOFFICE - TABELAS */}
                      <NavDropdown title={<span><FontAwesomeIcon icon={faTable} style={{ marginTop: '5px', marginRight: '5px' }} />Tabelas</span>} id="contrato-dropdown">
                        <NavDropdown.Item href="/backoffice-tabelas/historico-financeiro/">
                          <FontAwesomeIcon icon={faBook} style={{ marginRight: '5px' }} />Histórico Financeiro
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/backoffice-tabelas/fidc">
                          <FontAwesomeIcon icon={faBook} style={{ marginRight: '5px' }} />FIDC
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/backoffice-tabelas/cessao">
                          <FontAwesomeIcon icon={faBook} style={{ marginRight: '5px' }} />Cessão
                        </NavDropdown.Item>
                      </NavDropdown>
                    </NavDropdown>
                  </div>
                  <div style={{ marginTop: '25px', width: '100%', justifyContent: 'center', display: 'flex' }}>
                    {/* {nomeUsuario && <span style={{ marginLeft: '19px', fontWeight: 'bold' }}>Olá, {nomeUsuario}</span>} */}
                    <Button style={{ height: 'fit-content', marginRight: '15px', width: '190px', marginTop: '10px' }} variant="outline-danger" onClick={() => { window.location.href = '/login' }}>
                      <FontAwesomeIcon icon={faSignOut} style={{ marginRight: '15px' }} />Sair
                    </Button>
                  </div>

                </Offcanvas.Body>
              </Offcanvas>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
            </Container>
          </Row>
        </Navbar>

        <div className="col-md-2">
          {/* MENU ACIMA */}
          <Navbar expand="lg" className="row align-items-center">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Button style={{ backgroundColor: '#3d6062', borderColor: '#24393a', marginLeft: '5px' }} variant="primary" onClick={handleShow}>
                <FontAwesomeIcon icon={faList} />
              </Button>

              <Nav className="justify-content-center flex-grow-1 pe-3" style={{ marginLeft: '120%' }}>
                {/* PROPOSTA */}
                <Nav.Item>
                  <Nav.Link onClick={() => window.location.href = '/proposta-cartao'} style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>
                    <FontAwesomeIcon icon={faCalculator} style={{ marginRight: '5px' }} />
                    Simulação - Cartão
                  </Nav.Link>
                </Nav.Item>

                {/* ESTEIRA */}
                <Nav.Item>
                  <Nav.Link onClick={() => window.location.href = '/esteira-proposta/FLUXO'} style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>
                    <FontAwesomeIcon icon={faList} style={{ marginRight: '5px' }} />
                    Esteira - Propostas
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link onClick={() => window.location.href = '/esteira-proposta/LOTE'} style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>
                    <FontAwesomeIcon icon={faTasksAlt} style={{ marginRight: '5px' }} />
                    Esteira - Processamento em Lote
                  </Nav.Link>
                </Nav.Item>

                {/* CLIENTE */}
                {/* <FontAwesomeIcon icon={faUser} style={{ marginTop: '11px' }} />
                <NavDropdown title="Clientes" id="basic-nav-dropdown">
                  <NavDropdown.Item href="/cliente-consulta/LIMITE">
                    <FontAwesomeIcon icon={faMagnifyingGlass} style={{ marginRight: '5px' }} />Consulta Limite
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/cliente-consulta/ATUALIZACAO">
                    <FontAwesomeIcon icon={faUserCircle} style={{ marginRight: '5px' }} />Atualização Cadastral
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/cliente-consulta-historico">
                    <FontAwesomeIcon icon={faHistory} style={{ marginRight: '5px' }} />Consulta Histórico
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/cliente-aprovar-atualizacao">
                    <FontAwesomeIcon icon={faCheck} style={{ marginRight: '5px' }} />Aprovar Atualização
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/cliente-relatorio">
                    <FontAwesomeIcon icon={faList} style={{ marginRight: '5px' }} />Relatório de Clientes
                  </NavDropdown.Item>
                </NavDropdown> */}

                {/* RELATORIOS */}
                <Nav.Item>
                  <Nav.Link onClick={() => window.location.href = '/relatorio-analitico'} style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>
                    <FontAwesomeIcon icon={faListOl} style={{ marginRight: '5px' }} />
                    Relatório - Produção Analítico
                  </Nav.Link>
                </Nav.Item>

                {/* <Nav.Item>
                  <Nav.Link onClick={() => window.location.href = '/relatorio-extrato-mensal'} style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>
                    <FontAwesomeIcon icon={faListOl} style={{ marginRight: '5px' }} />
                    Relatório - Extrato Mensal
                  </Nav.Link>
                </Nav.Item> */}

                {/* ORIGENS */}
                {/* <FontAwesomeIcon icon={faBuilding} style={{ marginTop: '11px' }} />
                <NavDropdown title="Origens" id="basic-nav-dropdown">
                  <NavDropdown.Item href="/origens-matriz">
                    <FontAwesomeIcon icon={faBuilding} style={{ marginRight: '5px' }} />Matriz
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/origens-promotora">
                    <FontAwesomeIcon icon={faBuilding} style={{ marginRight: '5px' }} />Promotora
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/origens-supervisor">
                    <FontAwesomeIcon icon={faUserCircle} style={{ marginRight: '5px' }} />Supervisor
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/origens-operador">
                    <FontAwesomeIcon icon={faUserCircle} style={{ marginRight: '5px' }} />Operador
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/origens-ponto-venda">
                    <FontAwesomeIcon icon={faBuilding} style={{ marginRight: '5px' }} />Ponto de Venda
                  </NavDropdown.Item>
                </NavDropdown> */}

                {/* PARAMETROS CARTAO */}
                {/* <FontAwesomeIcon icon={faListCheck} style={{ marginTop: '11px' }} />
                <NavDropdown title="Parâmetros Cartão" id="basic-nav-dropdown">
                  <NavDropdown.Item href="/parametros-esteira">
                    <FontAwesomeIcon icon={faList} style={{ marginRight: '5px' }} />Esteiras
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/parametros-produto">
                    <FontAwesomeIcon icon={faRuble} style={{ marginRight: '5px' }} />Produtos
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/parametros-empregador">
                    <FontAwesomeIcon icon={faBuilding} style={{ marginRight: '5px' }} />Empregadores
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/parametros-financiamento">
                    <FontAwesomeIcon icon={faCalculator} style={{ marginRight: '5px' }} />Tabela de Financiamento
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/motivo-recusa">
                    <FontAwesomeIcon icon={faList} style={{ marginRight: '5px' }} />Motivos Recusa/Pendência
                  </NavDropdown.Item>
                </NavDropdown> */}

                {/* COMUNICADOS */}
                {/* <FontAwesomeIcon icon={faComment} style={{ marginTop: '11px' }} />
                <NavDropdown title="Comunicados" id="basic-nav-dropdown">
                  <NavDropdown.Item href="/comunicados-vigentes">
                    <FontAwesomeIcon icon={faComment} style={{ marginRight: '5px' }} />Comunicados Vigentes
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/comunicados-tipos">
                    <FontAwesomeIcon icon={faCommenting} style={{ marginRight: '5px' }} />Tipos de Comunicados
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/comunicados">
                    <FontAwesomeIcon icon={faComments} style={{ marginRight: '5px' }} />Comunicados
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/comunicados-confirmacao">
                    <FontAwesomeIcon icon={faCommentSms} style={{ marginRight: '5px' }} />Confirmação de Comunicados
                  </NavDropdown.Item>
                </NavDropdown> */}

                {/* SISTEMA */}
                {/* <FontAwesomeIcon icon={faSitemap} style={{ marginTop: '11px' }} />
                <NavDropdown title="Sistema" id="basic-nav-dropdown">
                  <NavDropdown.Item href="/sistema-parametros-ti">
                    <FontAwesomeIcon icon={faCog} style={{ marginTop: '11px', marginRight: '5px' }} />Parâmetros TI
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/sistema-usuario">
                    <FontAwesomeIcon icon={faUsers} style={{ marginTop: '11px', marginRight: '5px' }} />Usuários
                  </NavDropdown.Item>
                </NavDropdown> */}

                {/* BACKOFFICE */}
                {/* <FontAwesomeIcon icon={faBuildingColumns} style={{ marginTop: '11px' }} />
                <NavDropdown title="BackOffice" id="basic-nav-dropdown">
                  <NavDropdown.Item href="/backoffice-contrato/consulta">
                    <FontAwesomeIcon icon={faMagnifyingGlass} style={{ marginRight: '5px' }} />Consulta de Contrato
                  </NavDropdown.Item>
                  <NavDropdown title={<span><FontAwesomeIcon icon={faSitemap} style={{ marginTop: '11px', marginRight: '5px' }} />Lançamentos</span>} id="contrato-dropdown">
                    <NavDropdown.Item href="/backoffice-lancamentos/baixa-parcela/">
                      <FontAwesomeIcon icon={faMoneyBill} style={{ marginRight: '5px' }} />Baixa de Parcelas - Manual
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/backoffice-contrato/prorrogacao-vencimento">
                      <FontAwesomeIcon icon={faMinusCircle} style={{ marginRight: '5px' }} />Prorrogação de Vencimentos - Manual
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/backoffice-arquivo-lote/LANCAMENTO">
                      <FontAwesomeIcon icon={faArchive} style={{ marginRight: '5px' }} />Processos Em Lote
                    </NavDropdown.Item> */}

                {/* BACKOFFICE - LANCAMENTOS */}
                {/* <NavDropdown.Item href="/backoffice-lancamentos/estorno-parcela">
                      <FontAwesomeIcon icon={faMinusCircle} style={{ marginRight: '5px' }} />Estorno de Parcela
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/backoffice-lancamentos/cancelamento-movimentacao">
                      <FontAwesomeIcon icon={faTrash} style={{ marginRight: '5px' }} />Cancelamento de Movimentação
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/backoffice-lancamentos/estorno-operacao">
                      <FontAwesomeIcon icon={faUndo} style={{ marginRight: '5px' }} />Estorno de Operação
                    </NavDropdown.Item>
                  </NavDropdown> */}

                {/* BACKOFFICE - CESSAO */}
                {/* <NavDropdown title={<span><FontAwesomeIcon icon={faMoneyBill} style={{ marginTop: '11px', marginRight: '5px' }} />Cessão</span>} id="contrato-dropdown">
                    <NavDropdown.Item href="/backoffice-cessao/lancamento-manual">
                      <FontAwesomeIcon icon={faShareSquare} style={{ marginRight: '5px' }} />Lançamento Manual
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/backoffice-cessao/recompra-manual">
                      <FontAwesomeIcon icon={faUndoAlt} style={{ marginRight: '5px' }} />Recompra Manual
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/backoffice-arquivo-lote/CESSAO">
                      <FontAwesomeIcon icon={faArchive} style={{ marginRight: '5px' }} />Processos Em Lote
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/backoffice-cessao/remessa-liquidacao">
                      <FontAwesomeIcon icon={faArrowAltCircleUp} style={{ marginRight: '5px' }} />Remessa Liquidação
                    </NavDropdown.Item>
                  </NavDropdown> */}

                {/* BACKOFFICE -RELATORIOS */}
                {/* <NavDropdown title={<span><FontAwesomeIcon icon={faList} style={{ marginTop: '11px', marginRight: '5px' }} />Relatórios</span>} id="contrato-dropdown">
                    <NavDropdown.Item href="/backoffice-relatorios/operacoes-realizadas/">
                      <FontAwesomeIcon icon={faList} style={{ marginRight: '5px' }} />Operações Realizadas
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/backoffice-relatorios/vencimentos">
                      <FontAwesomeIcon icon={faList} style={{ marginRight: '5px' }} />De Vencimentos
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/backoffice-relatorios/movimentacao-financeira">
                      <FontAwesomeIcon icon={faList} style={{ marginRight: '5px' }} />Movimentação Financeira
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/backoffice-relatorios/contratos-cedidos">
                      <FontAwesomeIcon icon={faList} style={{ marginRight: '5px' }} />Contratos Cedidos
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/backoffice-relatorios/parcelas-cedidas">
                      <FontAwesomeIcon icon={faList} style={{ marginRight: '5px' }} />Parcelas Cedidas
                    </NavDropdown.Item>
                  </NavDropdown> */}

                {/* BACKOFFICE - TABELAS */}
                {/* <NavDropdown title={<span><FontAwesomeIcon icon={faTable} style={{ marginTop: '11px', marginRight: '5px' }} />Tabelas</span>} id="contrato-dropdown">
                    <NavDropdown.Item href="/backoffice-tabelas/historico-financeiro/">
                      <FontAwesomeIcon icon={faBook} style={{ marginRight: '5px' }} />Histórico Financeiro
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/backoffice-tabelas/fidc">
                      <FontAwesomeIcon icon={faBook} style={{ marginRight: '5px' }} />FIDC
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/backoffice-tabelas/cessao">
                      <FontAwesomeIcon icon={faBook} style={{ marginRight: '5px' }} />Cessão
                    </NavDropdown.Item>
                  </NavDropdown>
                </NavDropdown> */}
              </Nav>

            </Navbar.Collapse>
          </Navbar>
        </div>
      </div>
    </div>
  );
}

export default NavBar;