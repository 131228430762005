import 'bootstrap/dist/css/bootstrap.css';
import { useState } from 'react';
import Spinner from '../../shared/spinner';
import { Usuario } from '../../shared/model/usuario';
import UsuarioService from '../../services/usuarioService';
import CustomAlert from '../../shared/customAlert';

function UsuarioAlteracaoSenhaComponent() {
  const [alert, setAlert] = useState<{ message: string, type: 'success' | 'warning' | 'error' } | null>(null);
  const [senhaAntiga, setSenhaAntiga] = useState('');
  const [senha, setSenha] = useState('');
  const [senha2, setSenha2] = useState('');

  const [loading, setLoading] = useState(false);

  const usuarioService: UsuarioService = new UsuarioService();

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (senha !== senha2) return setAlert({ message: 'As senhas devem ser iguais!', type: 'warning' });

    let usuario: Usuario = new Usuario();
    usuario.senhaString = senha;
    usuario.senhaAntigaString = senhaAntiga;

    try {
      await usuarioService.alterarSenha(usuario);

      setLoading(false);
      setAlert({ message: 'Senha alterada com sucesso!', type: 'success' });
    } catch (err: any) {
      setLoading(false);
      setAlert({ message: err.response.data, type: 'error' });
      console.error(err);
    }
  };

  return (
    <div className="container">
      {alert && (<CustomAlert message={alert.message} type={alert.type} onClose={() => { setAlert(null); if (alert.type === 'success') { window.location.href = '/login' } }} />)}
      {loading ? <Spinner loading={loading} /> : (
        <form onSubmit={handleSubmit}>
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '50px' }}>
                <h5>Usuário &gt; Alteração de senha</h5>
            </div>
            <hr className="my-4" />
          </div>
          <div className="row" style={{ marginBottom: '50px' }}>
            <div className="col-xl">
              <label>Senha Antiga <span style={{ color: 'red' }}>*</span></label>
              <input required type="text" className="form-control" value={senhaAntiga} onChange={(e) => setSenhaAntiga(e.target.value)} />
            </div>
            <div className="col-xl">
              <label>Senha Nova <span style={{ color: 'red' }}>*</span></label>
              <input required type="password" className="form-control" value={senha} onChange={(e) => setSenha(e.target.value)} />
            </div>
            <div className="col-xl">
              <label>Confirmação Senha Nova <span style={{ color: 'red' }}>*</span></label>
              <input required type="password" className="form-control" value={senha2} onChange={(e) => setSenha2(e.target.value)} />
            </div>
          </div>

          <div>
            <hr className="my-4" />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <button className="w-40 btn btn-primary btn-lg btn btn-success" type="submit">Alterar Senha</button>
              </div>
            </div>
          </div>
        </form>
      )}
    </div >
  );
}

export default UsuarioAlteracaoSenhaComponent;