import 'bootstrap/dist/css/bootstrap.css';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Spinner from '../../shared/spinner';
import { PropostaDTO } from '../../shared/dto/propostaDTO';
import { format } from 'date-fns';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button, Form } from 'react-bootstrap';
import { PontoVendaDTO } from '../../shared/dto/pontoVendaDTO';
import { Promotora } from '../../shared/model/promotora';
import { Matriz } from '../../shared/model/matriz';
import { Operador } from '../../shared/model/operador';
import { Gerente } from '../../shared/model/gerente';
import { EsteiraModelo } from '../../shared/model/esteira_modelo';
import { SituacoesOpcoes } from '../../shared/constantes/situacaoEsteira';
import { FasesOpcoes } from '../../shared/constantes/fasesEsteira';
import { Produto } from '../../shared/model/produto';
import { FiltroPropostaDTO } from '../../shared/dto/filtroPropostaDTO';
import { Empregador } from '../../shared/model/empregador';
import { getLabelTipoProp, TiposProposta } from '../../shared/constantes/tiposProposta';
import InputMask from 'react-input-mask';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEye, faPrint, faRefresh, faFilter, faThumbsUp, faThumbsDown, faPaperPlane, faPlaneLock, faDatabase, faPhone, faIdCard } from '@fortawesome/free-solid-svg-icons';
import { RotasProcessoEsteira } from '../../shared/rotasProcessoEsteira';
import { AcaoPropostaEnum } from '../../shared/constantes/acaoPropostaEnum';
import { ProcessoPropostaEsteiraEnum } from '../../shared/constantes/processoPropostaEsteiraEnum';
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';
import ScrollToTopButton from '../../shared/scrollToTopButton';
import ReprovarOuPendenciarPropostaModal from '../../shared/reprovarOuPendenciarPropostaModal';
import PropostaService from '../../services/propostaService';
import MatrizService from '../../services/matrizService';
import PromotoraService from '../../services/promotoraService';
import SupervisorService from '../../services/supervisorService';
import OperadorService from '../../services/operadorService';
import EmpregadorService from '../../services/empregadorService';
import PropostaEsteiraService from '../../services/propostaEsteiraService';
import PontoVendaService from '../../services/pontoVendaService';
import ProdutoService from '../../services/produtoService';
import EsteiraModeloService from '../../services/esteiraModeloService';
import CustomAlert from '../../shared/customAlert';
import styled from 'styled-components';

function PropostasComponent() {
  const [alert, setAlert] = useState<{ message: string, type: 'success' | 'warning' | 'error' } | null>(null);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const { tipoTela } = useParams();
  const [propostasDTO, setPropostasDTO] = useState<PropostaDTO[]>([]);

  const [filtros, setFiltros] = useState(false);
  const [matriz, setMatriz] = useState('');
  const [matrizes, setMatrizes] = useState<Matriz[]>([]);
  const [promotora, setPromotora] = useState('');
  const [promotoras, setPromotoras] = useState<Promotora[]>([]);
  const [supervisor, setSupervisor] = useState('');
  const [supervisores, setSupervisores] = useState<Gerente[]>([]);
  const [operador, setOperador] = useState('');
  const [operadores, setOperadores] = useState<Operador[]>([]);
  const [pontoVenda, setPontoVenda] = useState('');
  const [pontoVendas, setPontoVendas] = useState<PontoVendaDTO[]>([]);
  const [esteira, setEsteira] = useState('');
  const [esteiras, setEsteiras] = useState<EsteiraModelo[]>([]);
  const situacoesOpcoes = SituacoesOpcoes;
  const [selecionadosSituacao, setSelecionadosSituacao] = useState<String[]>([]);
  const fasesOpcoes = FasesOpcoes;
  const [selecionadosFases, setSelecionadosFases] = useState<String[]>([]);
  const [dataInicio, setDataInicio] = useState<Date | undefined>();
  const [dataFim, setDataFim] = useState<Date | undefined>();
  const [produto, setProduto] = useState('');
  const [produtos, setProdutos] = useState<Produto[]>([]);
  const [tipoProposta, setTipoProposta] = useState('');
  const tiposProposta = TiposProposta;
  const [empregador, setEmpregador] = useState('');
  const [empregadores, setEmpregadores] = useState<Empregador[]>([]);
  const [numeroProposta, setNumeroProposta] = useState('');
  const [cpf, setCpf] = useState('');
  const [nomeCliente, setNomeCliente] = useState('');
  const [celularCliente, setCelularCliente] = useState('');

  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [atualizarPag, setAtualizarPag] = useState(false);

  const [pageSize, setPageSize] = useState(20);

  const qtdRegistros = [10, 20, 30, 50, 100];

  const [selecionarTudo, setSelecionarTudo] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [selectedMotivo, setSelectedMotivo] = useState('');

  const propostaService: PropostaService = new PropostaService();
  const matrizService: MatrizService = new MatrizService();
  const promotoraService: PromotoraService = new PromotoraService();
  const supervisorService: SupervisorService = new SupervisorService();
  const operadorService: OperadorService = new OperadorService();
  const propostaEsteiraService: PropostaEsteiraService = new PropostaEsteiraService();
  const pontoVendaService: PontoVendaService = new PontoVendaService();
  const empregadorService: EmpregadorService = new EmpregadorService();
  const produtoService: ProdutoService = new ProdutoService();
  const esteiraModeloService: EsteiraModeloService = new EsteiraModeloService();

  const handleModalCancel = () => {
    setShowModal(false);
  };

  const handleModalConfirm = (motivo: string) => {
    setSelectedMotivo(motivo);
    setShowModal(false);
    enviaAcaoEmLote(false, motivo);
  };

  const acaoEmLote = async (isAprovar: boolean) => {
    if (!isAprovar) {
      setShowModal(true);
    } else {
      enviaAcaoEmLote(isAprovar, null);
    }
  };

  const enviaAcaoEmLote = async (isAprovar: boolean, motivo: string | null) => {
    try {
      setLoading(true);
      const response = await propostaService.aprovarReprovarEmLote(isAprovar, motivo, propostasDTO);

      setLoading(false);
      setAlert({ message: response.data, type: 'success' });
      setAtualizarPag(!atualizarPag);
    } catch (err: any) {
      setLoading(false);
      console.error(err);
      setAlert({ message: err.response.data, type: 'error' });
    }
  };

  const selecionarDeselecionarTodos = (marcar: boolean) => {
    setSelecionarTudo(marcar);
    const updatedProposta = propostasDTO.map((item) => ({ ...item, selected: marcar }));
    setPropostasDTO(updatedProposta);
  };

  const handleSelecionarChange = (id: any) => {
    if (selecionarTudo) {
      setSelecionarTudo(false);
    }
    setPropostasDTO((prevData) => {
      const updatedProposta = prevData.map((item) =>
        item.id === id ? { ...item, selected: !item.selected } : item
      );
      const todosMarcadosAgora = todosMarcados(updatedProposta);
      setSelecionarTudo(todosMarcadosAgora);

      return updatedProposta;
    });
  };

  const todosMarcados = (proposta: PropostaDTO[]) => {
    return proposta.every((item) => item.selected);
  };

  const listarTodasPropostas = async (max: number, filtroBool: boolean) => {
    try {
      setLoading(true);
      let filtro = new FiltroPropostaDTO();
      if (filtroBool) {
        filtro.matriz = parseInt(matriz);
        filtro.promotora = parseInt(promotora);
        filtro.supervisor = parseInt(supervisor);
        filtro.operador = parseInt(operador);
        filtro.pontoVenda = parseInt(pontoVenda);
        filtro.esteira = parseInt(esteira);
        filtro.situacaoEsteira = selecionadosSituacao;
        filtro.faseEsteira = selecionadosFases;
        filtro.dataInicio = new Date(dataInicio!);
        filtro.dataFim = new Date(dataFim!);
        filtro.produto = parseInt(produto);
        filtro.tipoProposta = tipoProposta;
        filtro.empregador = parseInt(empregador);
        filtro.numeroProposta = parseInt(numeroProposta);
        filtro.cpf = cpf;
        filtro.nomeCliente = nomeCliente;
        filtro.celularCliente = celularCliente;
      }

      const responseList = await propostaService.listarTodasPropostas(currentPage, max, filtro);

      const jsonList: PropostaDTO[] = responseList.data.content;
      setPropostasDTO(jsonList);
      setTotalPages(responseList.data.totalPages);
      // jsonList.forEach((item: PropostaDTO, index) => {
      //   jsonList[index].dataAlteracao = (format(new Date(jsonList[index].dtAlteracao), 'dd/MM/yyyy'));
      // })
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const listarMatrizes = async () => {
    setLoading(true);
    try {
      await matrizService.listarTodasMatrizes()
        .then(resp => {
          setMatrizes(resp.data);
          setLoading(false);
        })
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const listarPromotoras = async () => {
    setLoading(true);
    try {
      await promotoraService.listarTodasPromotorasAtivas()
        .then(resp => {

          setPromotoras(resp.data);
          setLoading(false);
        })
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const listarSupervisores = async () => {
    setLoading(true);
    try {
      await supervisorService.listarTodosGerentes()
        .then(resp => {

          setSupervisores(resp.data);
          setLoading(false);
        })
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const listarOperadores = async () => {
    setLoading(true);
    try {
      await operadorService.listarTodosOperadores()
        .then(resp => {
          setOperadores(resp.data);
          setLoading(false);
        })
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const listarPontoVendas = async () => {
    setLoading(true);
    try {
      await pontoVendaService.listarTodosPontosVendas()
        .then(resp => {
          setPontoVendas(resp.data);
          setLoading(false);
        })
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const listarEsteiras = async () => {
    setLoading(true);
    try {
      const response = await esteiraModeloService.listarTodasEsteirasModelo()
        .then(resp => {
          setEsteiras(resp.data);
          setLoading(false);
        })
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const listarProdutos = async () => {
    setLoading(true);
    try {
      await produtoService.listarTodosProdutos()
        .then(resp => {
          setProdutos(resp.data);
          setLoading(false);
        })
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const listarEmpregadores = async () => {
    setLoading(true);
    try {
      await empregadorService.listarTodosEmpregadores()
        .then(resp => {
          setEmpregadores(resp.data);
          setLoading(false);
        })
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const handleCheckboxChangeSituacao = (opcao: string) => {
    if (selecionadosSituacao.includes(opcao)) {
      setSelecionadosSituacao(selecionadosSituacao.filter(item => item !== opcao));
    } else {
      setSelecionadosSituacao([...selecionadosSituacao, opcao]);
    }
  };

  const handleCheckboxChangeFases = (op: string) => {
    if (selecionadosFases.includes(op)) {
      setSelecionadosFases(selecionadosFases.filter(item => item !== op));
    } else {
      setSelecionadosFases([...selecionadosFases, op]);
    }
  };

  const gerarRelatorioProposta = async (idProposta: number) => {
    setLoading(true);
    try {

      await propostaService.listarPropostaEspelhoJasper(idProposta)
        .then(resp => {
          const blob = new Blob([resp.data], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          window.open(url);

          setLoading(false);
        })

    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const propostasComMesmoCpfTelefone = async (cpf: string, celularCliente: string) => {
    setCpf(cpf);
    let filtro = new FiltroPropostaDTO();
    filtro.cpf = cpf;
    filtro.celularCliente = celularCliente;

    const responseList = await propostaService.listarTodasPropostas(currentPage, pageSize, filtro);

    const jsonList: PropostaDTO[] = responseList.data.content;
    setPropostasDTO(jsonList);
    setTotalPages(responseList.data.totalPages);
  }

  const direcionamentoProcessoEsteira = (processo: string, idCliente: string, idProposta: string) => {
    if (tipoTela === 'LOTE') return;
    if (processo == ProcessoPropostaEsteiraEnum.APR_PROP_AUT) {
      aprovarProposta(idProposta, processo);
    } else {
      const route = RotasProcessoEsteira.find(route => route.label === processo);
      if (route && route.value) {
        if (route.value === 'BACKEND') {
          aprovarProposta(idProposta, processo);
        } else {
          const url = route.value
            .replace('{idCliente}', idCliente)
            .replace('{idProposta}', idProposta);
          const routeTempelte = JSON.parse(url);
          navigate(routeTempelte.path, { state: routeTempelte.state });
        }
      } else {
        // setAlert({ message: 'Proposta Finalizada!', type: 'warning' });
        aprovarProposta(idProposta, processo);
      }
    }
  };

  const aprovarProposta = async (idProposta: string, processo: string) => {
    if (processo === null || processo.length === 0) {
      processo = 'SEM_PROCESSO';
    }
    setLoading(true);
    try {
      await propostaEsteiraService.incluirPropostaEsteiraComExecucao(idProposta, AcaoPropostaEnum.APROVAR, processo, null)
        .then(resp => {

          setLoading(false);
          setAtualizarPag(!atualizarPag);
        })
      if (processo === 'AVERBACAO_RMC') {
        setAlert({ message: "Proposta averbada com sucesso", type: 'success' });
      } else if (processo === 'BPO_INC_FORM_DIG') {
        setAlert({ message: "Proposta enviada para formalização!", type: 'success' });
      }
    } catch (err: any) {
      setLoading(false);
      setAlert({ message: err.response.data, type: err.response.data.startsWith('Proposta em formalização') ? 'warning' : 'error' });
      console.error(err);
    }
  };

  useEffect(() => {
    console.log(window.innerWidth)
    listarTodasPropostas(pageSize, false);
    listarMatrizes();
    listarPromotoras();
    listarSupervisores();
    listarOperadores();
    listarPontoVendas();
    listarEsteiras();
    listarProdutos();
    listarEmpregadores();
  }, [currentPage, atualizarPag]);

  const getStatusColor = (status: string) => {
    switch (status) {
      case "CAD":
        return "#D3D3D3";
      case "AND":
        return "#00BFFF";
      case "PEN":
        return "#FFFF00";
      case "APR":
        return "#228B22";
      case "LIB":
        return "#228B22";
      case "PAG":
        return "#228B22";
      case "INT":
        return "#1E90FF";
      case "REP":
        return "#FF0000";
      default:
        return "transparent";
    }
  };

  return (
    <div className="position-relative m-md-3">
      {alert && (<CustomAlert message={alert.message} type={alert.type} onClose={() => setAlert(null)} />)}
      <div style={{ marginBottom: '15px', display: 'flex', justifyContent: 'space-between' }}>
        <h5>Esteira &gt; Cartão Consignado</h5>
      </div>

      <div style={{ marginBottom: '10px', display: 'flex', justifyContent: 'space-between' }}>
        <Button style={{ backgroundColor: '#3d6062', borderColor: '#3d6062' }} onClick={() => setFiltros(!filtros)}>
          <FontAwesomeIcon icon={faFilter} style={{ marginRight: '10px' }} />
          Filtros
        </Button>
        <Button style={{ backgroundColor: '#3d6062', borderColor: '#3d6062' }} onClick={() => listarTodasPropostas(pageSize, true)}>
          <FontAwesomeIcon icon={faRefresh} style={{ marginRight: '10px' }} />
          Atualizar Esteira
        </Button>
      </div>
      {filtros ?
        <>
          <Row className="mb-3">
            <Form.Group as={Col} md='4' controlId="formBasicSelect">
              <Form.Label>Matriz</Form.Label>
              <select className="form-select"
                onChange={(e) => {
                  setMatriz(e.target.value);
                }}
              >
                <option value="null">Selecione uma opção</option>
                {matrizes.map((item) => (
                  <option key={item.id} value={item.id!}>
                    {item.id + ' - ' + item.nome}
                  </option>
                ))}
              </select>
            </Form.Group>

            <Form.Group as={Col} md='4' controlId="formBasicSelect">
              <Form.Label>Promotora</Form.Label>
              <select className="form-select"
                defaultValue={promotora} value={promotora} onChange={(e) => setPromotora(e.target.value)}
              >
                <option value="0">Selecione uma opção</option>
                {promotoras.map((item) => (
                  <option key={item.id} value={item.id!}>
                    {item.id + ' - ' + item.nome}
                  </option>
                ))}
              </select>
            </Form.Group>

            <Form.Group as={Col} md='4' controlId="formBasicSelect">
              <Form.Label>Supervisor</Form.Label>
              <select className="form-select"
                defaultValue={supervisor} value={supervisor} onChange={(e) => setSupervisor(e.target.value)}
              >
                <option value="0">Selecione uma opção</option>
                {supervisores.map((item) => (
                  <option key={item.id} value={item.id!}>
                    {item.id + ' - ' + item.nome}
                  </option>
                ))}
              </select>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} md='4' controlId="formBasicSelect">
              <Form.Label>Operador</Form.Label>
              <select className="form-select"
                defaultValue={operador} value={operador} onChange={(e) => {
                  setOperador(e.target.value);
                }}
              >
                <option value="0">Selecione uma opção</option>
                {operadores.map((item) => (
                  <option key={item.id} value={item.id!}>
                    {item.id + ' - ' + item.nome}
                  </option>
                ))}
              </select>
            </Form.Group>

            <Form.Group as={Col} md='4' controlId="formBasicSelect">
              <Form.Label>Ponto de Venda</Form.Label>
              <select className="form-select"
                defaultValue={pontoVenda} value={pontoVenda} onChange={(e) => setPontoVenda(e.target.value)}
              >
                <option value="0">Selecione uma opção</option>
                {pontoVendas.map((item) => (
                  <option key={item.id} value={item.id!}>
                    {item.id + ' - ' + item.nome}
                  </option>
                ))}
              </select>
            </Form.Group>

            <Form.Group as={Col} md='4' controlId="formBasicSelect">
              <Form.Label>Esteira</Form.Label>
              <select className="form-select"
                defaultValue={esteira} value={esteira} onChange={(e) => setEsteira(e.target.value)}
              >
                <option value="0">Selecione uma opção</option>
                {esteiras.map((item) => (
                  <option key={item.id} value={item.id!}>
                    {item.id + ' - ' + item.nomeEsteira}
                  </option>
                ))}
              </select>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group style={{ display: 'flex', flexDirection: 'row' }}>
              <div>
                <label style={{ marginRight: '10px' }}>Situação Proposta:</label>
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                  {situacoesOpcoes.map((opcao, index) => (
                    <div key={index} style={{ marginRight: '5px' }}>
                      <input type="checkbox" id={opcao} value={opcao} checked={selecionadosSituacao.includes(opcao)} onChange={() => handleCheckboxChangeSituacao(opcao)} />
                      <label htmlFor={opcao} style={{ marginLeft: '5px' }}>{opcao}</label>
                    </div>
                  ))}
                </div>
              </div>

              <div style={{ marginLeft: '20px' }}>
                <label>Fase:</label>
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                  {fasesOpcoes.map((op, index) => (
                    <div key={index} style={{ marginRight: '5px' }}>
                      <input
                        type="checkbox"
                        id={op}
                        value={op}
                        checked={selecionadosFases.includes(op)}
                        onChange={() => handleCheckboxChangeFases(op)}
                      />
                      <label htmlFor={op} style={{ marginLeft: '5px' }}>{op}</label>
                    </div>
                  ))}
                </div>
              </div>
            </Form.Group>
          </Row>

          <Row style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
            <Form.Group as={Col} md='4'>
              <Form.Label>Data de Início:</Form.Label>
              <Form.Control
                type="date"
                value={dataInicio ? dataInicio.toISOString().split('T')[0] : ''}
                onChange={(e) => {
                  if (e.target.value) {
                    setDataInicio(new Date(e.target.value));
                  } else {
                    setDataInicio(undefined);
                  }
                }}
              />
            </Form.Group>
            <Form.Group as={Col} md='1' style={{ display: 'flex', marginTop: '30px', width: '1%' }}>
              <label>a</label>
            </Form.Group>
            <Form.Group as={Col} md='4'>
              <Form.Label>Data de Fim:</Form.Label>
              <Form.Control
                type="date"
                value={dataFim ? dataFim.toISOString().split('T')[0] : ''}
                onChange={(e) => {
                  if (e.target.value) {
                    setDataFim(new Date(e.target.value));
                  } else {
                    setDataFim(undefined);
                  }
                }}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3" style={{ marginTop: '15px' }}>
            <Form.Group as={Col} md='4' controlId="formBasicSelect">
              <Form.Label>Produto</Form.Label>
              <select className="form-select"
                onChange={(e) => {
                  setProduto(e.target.value);
                }}
              >
                <option value="0">Selecione uma opção</option>
                {produtos.map((item) => (
                  <option key={item.id} value={item.id!}>
                    {item.id + ' - ' + item.nome}
                  </option>
                ))}
              </select>
            </Form.Group>

            <Form.Group as={Col} md='4' controlId="formBasicSelect">
              <Form.Label>Tipo Proposta</Form.Label>
              <select className="form-select"
                defaultValue={tipoProposta} value={tipoProposta} onChange={(e) => setTipoProposta(e.target.value)}
              >
                <option value="0">Selecione uma opção</option>
                {tiposProposta.map((item) => (
                  <option key={item.label} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </Form.Group>

            <Form.Group as={Col} md='4' controlId="formBasicSelect">
              <Form.Label>Empregador</Form.Label>
              <select className="form-select"
                defaultValue={empregador} value={empregador} onChange={(e) => setEmpregador(e.target.value)}
              >
                <option value="0">Selecione uma opção</option>
                {empregadores.map((item) => (
                  <option key={item.id} value={item.id!}>
                    {item.id + ' - ' + item.nome}
                  </option>
                ))}
              </select>
            </Form.Group>
          </Row>

          <Row className="mb-4" style={{ marginTop: '15px' }}>
            <Form.Group as={Col} md='2' controlId="formBasicSelect">
              <Form.Label>Nº Proposta</Form.Label>
              <input type="text" className="form-control" value={numeroProposta} onChange={(e) => setNumeroProposta(e.target.value)} />
            </Form.Group>

            <Form.Group as={Col} md='2' controlId="formBasicSelect">
              <Form.Label>CPF</Form.Label>
              <InputMask required mask="999.999.999-99" type="text" className="form-control" value={cpf} onChange={(e) => setCpf(e.target.value)} />
            </Form.Group>

            <Form.Group as={Col} md='4' controlId="formBasicSelect">
              <Form.Label>Nome Cliente</Form.Label>
              <input type="text" className="form-control" value={nomeCliente} onChange={(e) => setNomeCliente(e.target.value)} />
            </Form.Group>

            <Form.Group as={Col} md='2' controlId="formBasicSelect">
              <Form.Label>Celular Cliente</Form.Label>
              <InputMask mask="(99)99999-9999" type="text" className="form-control"
                value={celularCliente} onChange={(e) => setCelularCliente((e.target.value).replace(/_/g, ""))}
              />
            </Form.Group>

          </Row>

          <div style={{ marginBottom: '10px', display: 'flex', justifyContent: 'space-between' }}>
            <Button style={{ backgroundColor: '#3d6062', borderColor: '#3d6062' }} onClick={() => setFiltros(!filtros)}>
              <FontAwesomeIcon icon={faFilter} style={{ marginRight: '1px' }} />
            </Button>
            <Button style={{ backgroundColor: '#3d6062', borderColor: '#3d6062' }} onClick={() => listarTodasPropostas(pageSize, true)}>
              <FontAwesomeIcon icon={faRefresh} style={{ marginRight: '1px' }} />
            </Button>
          </div>
        </>
        : null}
      {loading ? <Spinner loading={loading} /> : (
        <>
          <Row style={{ justifyContent: 'center' }} className="mb-3">
            <Form.Group style={{ marginTop: '10px' }} as={Col} md='2' controlId="formBasicSelect">Registros Por Página</Form.Group>
            <Form.Group as={Col} md='1' controlId="formBasicSelect">
              <select className="form-select"
                defaultValue={pageSize} value={pageSize} onChange={(e) => {
                  {
                    setPageSize(parseInt(e.target.value));
                    listarTodasPropostas(parseInt(e.target.value), true);
                  }
                }}
              >
                {qtdRegistros.map((qtd, index) => (
                  <option key={index} value={qtd}>{qtd}</option>
                ))}
              </select>
            </Form.Group>
            <Form.Group as={Col} md='2' controlId="formBasicSelect">
              <Button style={{ backgroundColor: '#3d6062', borderColor: '#3d6062' }} onClick={() => { setFiltros(false); listarTodasPropostas(pageSize, false) }}>
                <FontAwesomeIcon icon={faRefresh} style={{ marginRight: '5px' }} />
                Sem filtros
              </Button>
            </Form.Group>
          </Row>
          <div className="table-responsive m-md-0">
          <table id="App-reponsive-text" border={3} className="table table-bordered table-striped" style={{ width: '100%', padding: '2px 4px' }}>
          <thead>
                <tr className="GridHeaderStyle">
                  {tipoTela === 'LOTE' ?
                    <th scope="col" id="App-header-table" style={{ width: '1%', textAlign: 'center' }}><input type="checkbox"
                      checked={selecionarTudo} onChange={() => selecionarDeselecionarTodos(!selecionarTudo)}
                    /></th> : null}
                  <th scope="col" id="App-header-table">Sit</th>
                  <th scope="col" id="App-header-table">Prop</th>
                  <th scope="col" id="App-header-table">Contrato</th>
                  <th scope="col" id="App-header-table">Produto</th>
                  <th scope="col" id="App-header-table">Tipo Proposta</th>
                  <th scope="col" id="App-header-table">Promotora</th>
                  <th scope="col" id="App-header-table">Empregador</th>
                  <th scope="col" id="App-header-table">CPF/CNPJ</th>
                  <th scope="col" id="App-header-table">Matricula</th>
                  <th scope="col" id="App-header-table">Cliente</th>
                  <th scope="col" id="App-header-table">Valor Limite</th>
                  <th scope="col" id="App-header-table">RMC</th>
                  <th scope="col" id="App-header-table">Valor Saque</th>
                  <th scope="col" id="App-header-table">PMT Saque</th>
                  <th scope="col" id="App-header-table">Prazo Saque</th>
                  <th scope="col" id="App-header-table">Digitador</th>
                  <th scope="col" id="App-header-table">Atividade</th>
                  <th scope="col" id="App-header-table">Dt Mov</th>
                  <th scope="col" id="App-header-table">Ações</th>
                </tr>
              </thead>
              <tbody>
                {propostasDTO.map((item) => (
                  <tr className="GridRowStyle">
                    {tipoTela === 'LOTE' ? <td style={{ display: 'table-cell', verticalAlign: 'middle', textAlign: 'center', height: '100%' }}><input type="checkbox" checked={item.selected} onChange={() => handleSelecionarChange(item.id)} /></td> : null}
                    <td style={{ backgroundColor: getStatusColor(item.statusProposta), display: 'table-cell', verticalAlign: 'middle', textAlign: 'center', height: '100%' }}>
                      <Link to={`/esteira-proposta/atividades`} state={{ idProposta: item.id }} style={{ color: 'black', fontWeight: 'bold', textDecoration: 'none', display: 'inlie-block' }}>
                        {item.statusProposta}
                      </Link>
                    </td>
                    <td style={{ display: 'table-cell', verticalAlign: 'middle', textAlign: 'center' }}>{item.id}</td>
                    <td style={{ display: 'table-cell', verticalAlign: 'middle', textAlign: 'center' }}>{item.numeroContrato ? item.numeroContrato : '-'}</td>
                    <td style={{ display: 'table-cell', verticalAlign: 'middle', textAlign: 'center' }}>{item.produto}</td>
                    <td style={{ display: 'table-cell', verticalAlign: 'middle', textAlign: 'center' }}>{getLabelTipoProp(item.tipoProposta)}</td>
                    <td style={{ display: 'table-cell', verticalAlign: 'middle', textAlign: 'center' }}>{item.promotora}</td>
                    <td style={{ display: 'table-cell', verticalAlign: 'middle', textAlign: 'center' }}>{item.empregador}</td>
                    <td style={{ display: 'table-cell', verticalAlign: 'middle', textAlign: 'center' }}>{item.cpfCliente}</td>
                    <td style={{ display: 'table-cell', verticalAlign: 'middle', textAlign: 'center' }}>{item.numeroBeneficiarioCliente}</td>
                    <td style={{ display: 'table-cell', verticalAlign: 'middle', textAlign: 'center' }}>{item.nomeCliente}</td>
                    <td style={{ display: 'table-cell', verticalAlign: 'middle', textAlign: 'center' }}>{item.valorLimite.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                    <td style={{ display: 'table-cell', verticalAlign: 'middle', textAlign: 'center' }}>{item.valorRMCUtilizado.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                    <td style={{ display: 'table-cell', verticalAlign: 'middle', textAlign: 'center' }}>{item.limiteSaque.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                    <td style={{ display: 'table-cell', verticalAlign: 'middle', textAlign: 'center' }}>{item.pmt.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                    <td style={{ display: 'table-cell', verticalAlign: 'middle', textAlign: 'center' }}>{item.prazo}</td>
                    <td style={{ display: 'table-cell', verticalAlign: 'middle', textAlign: 'center' }}>{item.usuario}</td>
                    <td style={{ display: 'table-cell', verticalAlign: 'middle', textAlign: 'center' }}>
                      {tipoTela === 'LOTE' ? (<>{item.atividadeAtual}{item.statusFormalizacao && (<><br /><span style={{ fontSize: '0.8em', color: item.isSucessoAtualizacaoForm ? 'green' : 'red', textDecoration: 'none', textDecorationColor: item.isSucessoAtualizacaoForm ? 'green' : 'red' }}>{' (' + item.statusFormalizacao + ')'}</span></>)}</>
                      ) : (
                        <button
                          onClick={() => direcionamentoProcessoEsteira(item.processoEsteira, item.idCliente.toString(), item.id.toString())}
                          style={{ border: 'none', background: 'none', color: 'blue', textAlign: 'center' }}
                          type="button"
                        >
                          {item.atividadeAtual && (<span style={{ border: 'none', textDecoration: 'underline', background: 'none', color: 'blue', textAlign: 'center' }}>{item.atividadeAtual}</span>)}
                          {item.statusFormalizacao && (<><br /><span style={{ fontSize: '0.8em', color: item.isSucessoAtualizacaoForm ? 'green' : 'red', textDecoration: 'none', textDecorationLine: 'none', textDecorationColor: item.isSucessoAtualizacaoForm ? 'green' : 'red' }}>{' (' + item.statusFormalizacao + ')'}</span></>)}
                        </button>
                      )}
                    </td>
                    <td style={{ textAlign: 'center' }}>{item.dtMovimentacao ? format(new Date(item.dtMovimentacao), 'dd/MM/yyyy HH:mm') : ''}</td>
                    <td>
                      <div style={{ display: 'flex' }}>
                        {item.processoEsteira && item.processoEsteira.startsWith('BPO_INC_FORM_DIG') ? (
                          <Button id="App-reponsive-text"  style={{ background: 'grey', border: 'grey' }} title='Link de Formalização'
                            onClick={() => setAlert({ message: item.linkFormalizacao ? item.linkFormalizacao : 'Link não disponível', type: 'warning' })}>
                            <FontAwesomeIcon size="xs" icon={faPaperPlane} />
                          </Button>
                        ) : (
                          <Button className="btn btn-sm"  style={{ background: 'grey', border: 'grey' }}>
                            <FontAwesomeIcon size="xs" icon={faPaperPlane} style={{ color: 'black' }} />
                          </Button>
                        )}
                        <Link to={`/esteira-cliente/alteracao`} state={{ idCliente: item.idCliente, edicao: 'edicao', idProposta: item.id, atualizacaoCadastro: 'false' }}>
                          <Button className="btn btn-sm"  style={{ background: 'grey', border: 'grey', marginLeft: '5px' }} title='Cliente'>
                            <FontAwesomeIcon size="xs" icon={faUser} />
                          </Button>
                        </Link>
                        <Link to={`/proposta-esteira-component`} state={{ idProposta: item.id, esteira: 'N' }}>
                          <Button className="btn btn-sm"  style={{ background: 'grey', border: 'grey', marginLeft: '5px' }} title='Proposta'>
                            <FontAwesomeIcon size="xs" icon={faEye} />
                          </Button>
                        </Link>
                        <a onClick={() => gerarRelatorioProposta(item.id)}>
                          <Button className="btn btn-sm"  style={{ background: 'grey', border: 'grey', marginLeft: '5px' }} title='Relatório'>
                            <FontAwesomeIcon size="xs" icon={faPrint} />
                          </Button>
                        </a>
                        <a onClick={() => propostasComMesmoCpfTelefone(item.cpfCliente, '')}>
                          <Button className="btn btn-sm"  style={{ background: 'grey', border: 'grey', marginLeft: '5px' }} title='Propostas com mesmo CPF'>
                            <FontAwesomeIcon size="xs" icon={faIdCard} />
                          </Button>
                        </a>
                        <a onClick={() => propostasComMesmoCpfTelefone('', item.telefoneCelular)}>
                          <Button className="btn btn-sm"  style={{ background: 'grey', border: 'grey', marginLeft: '5px' }} title='Propostas com mesmo Celular'>
                            <FontAwesomeIcon size="xs" icon={faPhone} />
                          </Button>
                        </a>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <ResponsivePagination
              current={currentPage}
              total={totalPages}
              onPageChange={setCurrentPage} />
          </div>
          <ScrollToTopButton />
          {tipoTela === 'LOTE' ? (
            <>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <a className="btn btn-success" onClick={() => acaoEmLote(true)}>
                      <FontAwesomeIcon icon={faThumbsUp} />
                      Aprovar Propostas
                    </a>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <a className="btn btn-danger" onClick={() => acaoEmLote(false)}>
                      <FontAwesomeIcon icon={faThumbsDown} />
                      Reprovar Propostas
                    </a>
                  </div>
                </div>
              </div>

              <ReprovarOuPendenciarPropostaModal
                showModal={showModal}
                handleModalCancel={handleModalCancel}
                handleModalConfirm={handleModalConfirm}
                tipoPendenciaRecusa={"R"} />
            </>
          ) : null}
        </>
      )}
    </div>
  );

const ResponsiveText = styled.div`
font-size: 12px;

@media (max-width: 1280px) {
  font-size: 8px;
}
`;
}

export default PropostasComponent;
