import { Role } from "./role";

export class Usuario {
  public id!: number;
  public ativo!: boolean;
  public dtInclusao!: Date;
  public login!: string;
  public senhaString!: string;
  public senhaAntigaString!: string;
  public nome!: string;
  public cpf!: string;
  public dtNascimento!: Date;
  public role!: Role;
  public email!: string;
  public idUsuarioCadastro!: string;
  public dtAlteracao!: Date;

}
