import { UUID } from "crypto";

export class FiltroUsuarioDTO {
  public codigo!: string;
  public login!: string;
  public nome!: string;
  public cpf!: string;
  public status!: boolean | null;
  public idRole!: UUID;
  public dataInicio!: Date;
  public dataFim!: Date;
}