import 'bootstrap/dist/css/bootstrap.css';
import { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import { Esteira } from '../../../shared/model/esteira';
import Spinner from '../../../shared/spinner';
import { ButtonAlterar, ButtonAtivar, ButtonInativar } from '../../../shared/buttons';
import EsteiraModeloService from '../../../services/esteiraModeloService';
import ConfirmModal from '../../../shared/customAlertConfirm';
import { FiltroSimplesDTO } from '../../../shared/dto/filtroSimplesDTO';
import { faFilter, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ResponsivePagination from 'react-responsive-pagination';

function EsteiraComponent() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [esteira, setEsteiras] = useState<Esteira[]>([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [currentAction, setCurrentAction] = useState<{ id: any; ativo: boolean } | null>(null);

  const [filtros, setFiltros] = useState(false);
  const [codigoFiltro, setCodigoFiltro] = useState('');
  const [nomeFiltro, setNomeFiltro] = useState('');
  const [pageSize, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const esteiraModeloService: EsteiraModeloService = new EsteiraModeloService();

  const listarTodasEsteirasModelo = async (max: number) => {
    try {
      setLoading(true);
      let filtro = new FiltroSimplesDTO();
      filtro.codigo = codigoFiltro;
      filtro.nome = nomeFiltro;
      const responseList = await esteiraModeloService.listarTodasEsteirasModeloPaginado(currentPage, max, filtro);
      const jsonList: Esteira[] = responseList.data.content;
      setEsteiras(jsonList);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const ativarInativarEsteira = async (id: any, ativo: boolean) => {
    setCurrentAction({ id, ativo });
    setConfirmOpen(true);
  };

  const handleConfirm = async () => {
    if (currentAction) {
      const { id, ativo } = currentAction;

      try {
        await esteiraModeloService.ativarInativarEsteira(id);
        listarTodasEsteirasModelo(pageSize);
      } catch (err) {
        console.error(err);
      } finally {
        setConfirmOpen(false);
        setCurrentAction(null);
      }
    }
  };

  useEffect(() => {
    listarTodasEsteirasModelo(pageSize);
  }, []);


  return (
    <div className="position-relative m-md-3">
      <div style={{ marginBottom: '15px', display: 'flex', justifyContent: 'space-between' }}>
        <h5>Parâmetros Cartão &gt; Esteira</h5>
        <Button style={{ backgroundColor: '#3d6062', borderColor: '#3d6062' }} onClick={() => setFiltros(!filtros)}>
          <FontAwesomeIcon icon={faFilter} style={{ marginRight: '10px' }} />
          Filtros
        </Button>
        <Button
          onClick={() => navigate('/parametros-esteira/inclusao', { state: { id: null } })}
          style={{ backgroundColor: '#3d6062', borderColor: '#3d6062' }}
        >
          + Nova Esteira
        </Button>
      </div>
      {filtros ?
        <>
          <Row className="mb-4" style={{ marginTop: '15px' }}>
            <Form.Group as={Col} md='2' controlId="formBasicSelect">
              <Form.Label>Código </Form.Label>
              <input type="text" className="form-control" value={codigoFiltro} onChange={(e) => setCodigoFiltro(e.target.value)} />
            </Form.Group>
            <Form.Group as={Col} md='4' controlId="formBasicSelect">
              <Form.Label>Nome</Form.Label>
              <input type="text" className="form-control" value={nomeFiltro} onChange={(e) => setNomeFiltro(e.target.value)} />
            </Form.Group>
          </Row>
          <div style={{ marginBottom: '10px', display: 'flex', justifyContent: 'space-between' }}>
            <Button style={{ backgroundColor: '#3d6062', borderColor: '#3d6062' }} onClick={() => setFiltros(!filtros)}>
              <FontAwesomeIcon icon={faFilter} style={{ marginRight: '1px' }} />
            </Button>
            <Button style={{ backgroundColor: '#3d6062', borderColor: '#3d6062' }} onClick={() => listarTodasEsteirasModelo(pageSize)}>
              <FontAwesomeIcon icon={faRefresh} style={{ marginRight: '1px' }} />
            </Button>
          </div>
        </>
        : null}
      {loading ? <Spinner loading={loading} /> : (
        <div className="table-responsive">
          <div>
            <table className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th scope="col" id="App-header-table">Código</th>
                  <th scope="col-xl" id="App-header-table">Nome</th>
                  <th scope="col" id="App-header-table">Alteração</th>
                  <th scope="col" id="App-header-table">Status</th>
                </tr>
              </thead>
              <tbody>
                {esteira.map((item) => (
                  <tr>
                    <td>{item.id}</td>
                    <td>{item.nomeEsteira}</td>
                    <td>
                      <Link to={`/parametros-esteira/alteracao`} state={{ idEsteira: item.id, nomeEst: item.nomeEsteira }}>
                        <ButtonAlterar />
                      </Link>
                    </td>

                    <td>
                      {item.ativo ?
                        <ButtonInativar onClick={() => ativarInativarEsteira(item.id, item.ativo)} />
                        :
                        <ButtonAtivar onClick={() => ativarInativarEsteira(item.id, item.ativo)} />
                      }
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <ResponsivePagination
              current={currentPage}
              total={totalPages}
              onPageChange={setCurrentPage} />
            {confirmOpen && (<ConfirmModal message={currentAction?.ativo ? 'Tem certeza que deseja inativar este item?' : 'Tem certeza que deseja ativar este item?'} onConfirm={handleConfirm} onCancel={() => { setConfirmOpen(false); setCurrentAction(null) }} />)}
          </div>
        </div>
      )}
    </div>
  );
}

export default EsteiraComponent;