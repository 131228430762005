import createAxiosInstance from './axiosConfig';
import { FiltroUsuarioDTO } from '../shared/dto/filtroUsuarioDTO';
import { Usuario } from '../shared/model/usuario';
import { OrigemUsuario } from '../shared/model/origemUsuario';

const { REACT_APP_ESTEIRA_BACK } = process.env;

const axiosInstance = createAxiosInstance(REACT_APP_ESTEIRA_BACK + '/rest/usuarioController');

export default class UsuarioService {
  axios: any;

  constructor() {
    this.axios = axiosInstance;
  }

  async listarTodosUsuarios(currentPage: number, max: number, filtro: FiltroUsuarioDTO) {
    return await this.axios.post(`/listarTodosUsuarios?page=${currentPage}&max=${max}`, filtro);
  };

  async ativarInativarUsuario(id: string) {
    return await this.axios.get(`/ativarInativarUsuario?id=${id}`);
  };

  async listarUsuarioComOrigemPorId(id: string) {
    return await this.axios.get(`/listarUsuarioComOrigemPorId?idUsuario=${id}`);
  };

  async incluirAlterarUsuario(dto: OrigemUsuario) {
    return await this.axios.post(`/incluirAlterarUsuario`, dto);
  };

  async alterarSenha(dto: Usuario) {
    return await this.axios.post(`/alterarSenha`, dto);
  };

  async listarRoles() {
    return await this.axios.get(`/listarRoles`);
  };

  async listarOrigensPorRole(role: string) {
    return await this.axios.get(`/listarOrigensPorRole?idRole=${role}`);
  };

}