import axios from 'axios';

const { REACT_APP_ESTEIRA_BACK } = process.env;

export default class UsuarioExternoService {
  axios: any;
  constructor() {
    this.axios = axios.create({
      baseURL: REACT_APP_ESTEIRA_BACK + '/api'
    })
  }

  async login(dados: any) {
    const { data } = await this.axios.post('/usuarioControllerExterno/login', dados);
    if (data) {
      localStorage.setItem("nome", data.nome);
      localStorage.setItem("role", data.role);
      localStorage.setItem("token", data.token);

      return true;
    }
    return;
  };

  usuarioAutenticado() {
    return localStorage.getItem("token") !== undefined && localStorage.getItem("token") !== null ? true : false;
  };

  async logout() {
    localStorage.removeItem("nome");
    localStorage.removeItem("tipoPerfil");
    localStorage.removeItem("token");
  }
}