export class FiltroPropostaDTO {
  public matriz!: number;
  public promotora!: number;
  public supervisor!: number;
  public operador!: number;
  public pontoVenda!: number;
  public esteira!: number;
  public situacaoEsteira!: String[];
  public faseEsteira!: String[];
  public dataInicio!: Date;
  public dataFim!: Date;
  public produto!: number;
  public tipoProposta!: string;
  public empregador!: number;
  public numeroProposta!: number;
  public cpf!: string;
  public nomeCliente!: string;
  public celularCliente!: string;
}