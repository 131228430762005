import 'bootstrap/dist/css/bootstrap.css';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom';
import Spinner from '../../shared/spinner';
import InputMask from 'react-input-mask';
import { format, parse, set } from 'date-fns';
import { Usuario } from '../../shared/model/usuario';
import { cpf as cpfValidacao } from 'cpf-cnpj-validator';
import UsuarioService from '../../services/usuarioService';
import CustomAlert from '../../shared/customAlert';
import { Role } from '../../shared/model/role';
import { UUID } from 'crypto';
import { Matriz } from '../../shared/model/matriz';
import { OrigemUsuario } from '../../shared/model/origemUsuario';

interface Origem {
  id: number;
  nome: string;
}

function UsuarioInclusaoAlteracao() {
  const [alert, setAlert] = useState<{ message: string, type: 'success' | 'warning' | 'error' } | null>(null);

  const location = useLocation();
  const { id } = location.state || {};
  const { tipo } = useParams();
  const [dtInclusao, setDtInclusao] = useState('');
  const [nome, setNome] = useState('');
  const [cpf, setCpf] = useState('');
  const [dtNascimento, setDtNascimento] = useState('');
  const [email, setEmail] = useState('');
  const [login, setLogin] = useState('');
  const [roles, setRoles] = useState<Role[]>([]);
  const [role, setRole] = useState<Role | null>(null);
  const [senha, setSenha] = useState('');
  const [senha2, setSenha2] = useState('');
  const [origemVinculo, setOrigemVinculo] = useState<number>(0);

  const [origensList, setOrigensList] = useState<Origem[]>([]);
  const [erroOrigemList, setErroOrigemList] = useState(false);

  const [loading, setLoading] = useState(false);

  const [availableMatrizes, setAvailableMatrizes] = useState<Matriz[]>([]);
  const [selectedMatrizes, setSelectedMatrizes] = useState<Matriz[]>([]);
  const [selectedToMoveMatrizes, setSelectedToMoveMatrizes] = useState<Matriz[]>([]);
  const [selectedFromSelectedMatrizes, setSelectedFromSelectedMatrizes] = useState<Matriz[]>([]);

  const usuarioService: UsuarioService = new UsuarioService();

  const moveToSelectedMatrizes = () => {
    const newSelected = [...selectedMatrizes, ...selectedToMoveMatrizes];
    const newAvailable = availableMatrizes.filter(
      (matriz) => !selectedToMoveMatrizes.includes(matriz)
    );

    setAvailableMatrizes(newAvailable);
    setSelectedMatrizes(newSelected);
    setSelectedToMoveMatrizes([]);
  };

  const moveToAvailableMatrizes = () => {
    const newAvailable = [...availableMatrizes, ...selectedFromSelectedMatrizes];
    const newSelected = selectedMatrizes.filter(
      (matriz) => !selectedFromSelectedMatrizes.includes(matriz)
    );

    setAvailableMatrizes(newAvailable);
    setSelectedMatrizes(newSelected);
    setSelectedFromSelectedMatrizes([]);
  };

  const moveAllToSelectedMatrizes = () => {
    const newSelected = [...selectedMatrizes, ...availableMatrizes];
    setSelectedMatrizes(newSelected);
    setAvailableMatrizes([]);
  };

  const moveAllToAvailableMatrizes = () => {
    const newAvailable = [...availableMatrizes, ...selectedMatrizes];
    setAvailableMatrizes(newAvailable);
    setSelectedMatrizes([]);
  };

  const listarUsuarioPorId = async () => {
    setLoading(true);
    try {
      const response = await usuarioService.listarUsuarioComOrigemPorId(id);
      setSelectedMatrizes(response.data.map((origens: { idOrigem: number; nomeOrigem: string }) => ({
        id: origens.idOrigem,
        nome: origens.nomeOrigem,
      })));
      const json = response.data[0].usuario;
      console.log(json);
      setDtInclusao(json.dtInclusao ? format(new Date(json.dtInclusao), 'dd/MM/yyyy') : '');
      setNome(json.nome);
      setCpf(json.cpf);
      setDtNascimento(json.dtNascimento ? format(new Date(json.dtNascimento), 'dd/MM/yyyy') : '');
      setEmail(json.email);
      setRole(json.role);
      setLogin(json.login);
      setOrigemVinculo(response.data[0].idOrigem!);

      const responseRole = await usuarioService.listarOrigensPorRole(json.role?.id!);
      if (responseRole.data.length !== 0) {
        setOrigensList(responseRole.data);
        setAvailableMatrizes(responseRole.data.filter(
          (origem: { id: number }) => !response.data.map((origens: { idOrigem: number; nomeOrigem: string }) => ({
            id: origens.idOrigem,
            nome: origens.nomeOrigem,
          })).some((matriz: { id: number; }) => matriz.id === origem.id)));
      }

      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      setAlert({ message: err.response.data, type: 'error' });
      console.error(err);
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!cpfValidacao.isValid(cpf)) return setAlert({ message: 'CPF Inválido', type: 'warning' });
    let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(email) === false) return setAlert({ message: 'E-mail Inválido', type: 'warning' });
    if (senha !== senha2) return setAlert({ message: 'As senhas devem ser iguais!', type: 'warning' });

    let usuario: Usuario = new Usuario();
    usuario.id = id;
    usuario.nome = nome;
    usuario.cpf = cpf;
    usuario.dtNascimento = parse(dtNascimento, 'dd/MM/yyyy', new Date());
    usuario.email = email;
    usuario.role = role!;
    usuario.login = login;
    usuario.senhaString = senha;
    
    let origemUsuario: OrigemUsuario = new OrigemUsuario();
    origemUsuario.usuario = usuario;
    
    if (role?.descricao === 'Matriz') {
      origemUsuario.idsMatrizes = selectedMatrizes.map((matriz) => matriz.id!);
    }else if (role?.descricao !== 'Master'){
      origemUsuario.idOrigem = origemVinculo;
      origemUsuario.nomeOrigem = origensList.find((origem) => origem.id === origemVinculo)!.nome;
    }

    try {
      await usuarioService.incluirAlterarUsuario(origemUsuario);

      setLoading(false);
      if (tipo === 'inclusao') {
        setAlert({ message: 'Usuário salvo com sucesso!', type: 'success' });
      } else {
        setAlert({ message: 'Usuário alterado com sucesso!', type: 'success' });
      }
    } catch (err: any) {
      setLoading(false);
      setAlert({ message: err.response.data, type: 'error' });
      console.error(err);
    }
  };

  const listarOrigensPorRole = async (role: Role) => {
    setOrigensList([]);
    setOrigemVinculo(0);
    setAvailableMatrizes([]);
    setSelectedMatrizes([]);
    try {
      setLoading(true);

      const response = await usuarioService.listarOrigensPorRole(role.id);
      if (response.data.length !== 0) {
        setOrigensList(response.data);
        setOrigemVinculo(response.data[0].id!);
        setAvailableMatrizes(response.data);
      }

      setErroOrigemList(false);
    } catch (err: any) {
      setErroOrigemList(true);
      setAlert({ message: err.response.data, type: 'error' });
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const listarRoles = async () => {
    try {
      const response = await usuarioService.listarRoles();
      setRoles(response.data);
    } catch (err: any) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (tipo === 'alteracao') {
      listarUsuarioPorId();
    }
    listarRoles();
  }, []);

  return (
    <div className="container">
      {alert && (<CustomAlert message={alert.message} type={alert.type} onClose={() => { setAlert(null); if (alert.type === 'success') { window.history.back(); } }} />)}
      {loading ? <Spinner loading={loading} /> : (
        <form onSubmit={handleSubmit}>
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
              {tipo === 'inclusao' ? <h5>Usuário &gt; Cadastro</h5> : (
                <h5>Usuário &gt; Alteração</h5>
              )}
              <Button style={{ backgroundColor: '#5a8e91', borderColor: '#5a8e91' }} onClick={() => window.history.back()}>
                Voltar
              </Button>
            </div>
            <hr className="my-4" />
          </div>

          <div className="row" style={{ marginTop: '20px' }}>
            {tipo === 'alteracao' ? (
              <>
                <div className="col-md-1">
                  <label>Código</label>
                  <input readOnly type="text" className="form-control" value={id} style={{ backgroundColor: 'LightGrey' }} />
                </div>
                <div className="col-md-2">
                  <label>Data Criação</label>
                  <input readOnly type="text" className="form-control" value={dtInclusao} style={{ backgroundColor: 'LightGrey' }} />
                </div>
              </>
            ) : null}
            <div className="col-xl">
              <label>Nome Completo <span style={{ color: 'red' }}>*</span></label>
              <input required type="text" className="form-control" value={nome} onChange={(e) => setNome(e.target.value)} />
            </div>
            <div className="col-xl">
              <div className="form-group">
                <label>CPF <span style={{ color: 'red' }}>*</span></label>
                <InputMask required mask="999.999.999-99" type="text" className="form-control" value={cpf!} onChange={(e) => { setCpf(e.target.value); }} />
              </div>
            </div>
            <div className="col-xl">
              <label>Data Nascimento <span style={{ color: 'red' }}>*</span></label>
              <InputMask required mask="99/99/9999" className="form-control" type="text" value={dtNascimento} onChange={(e) => setDtNascimento((e.target.value).replace(/_/g, ""))} />
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <label>E-mail <span style={{ color: 'red' }}>*</span></label>
              <input required type="text" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div className="col-md-4">
              <label>Tipo de Usuário <span style={{ color: "red" }}>*</span></label>
              <select required className="form-select" value={role?.id || ""} onChange={(e) => {
                const selectedRole = roles.find((item) => item.id === e.target.value as UUID); setRole(selectedRole!); listarOrigensPorRole(selectedRole!);
              }}
              >
                <option value="" disabled>Selecione um tipo de usuário</option>
                {roles.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.descricao}
                  </option>
                ))}
              </select>
            </div>

            {(role?.descricao !== 'Master' && role?.descricao !== 'Matriz' && role !== null) && (
              <div className="col-md-4">
                <label>Origem Vinculo <span style={{ color: "red" }}>*</span></label>
                <select required className="form-select" value={origemVinculo} onChange={(e) => setOrigemVinculo(parseInt(e.target.value))}>
                  {origensList.map((item) => (
                    <option key={item?.id} value={item?.id!}>
                      {item.id} - {item.nome}
                    </option>
                  ))}
                </select>
              </div>
            )}

            <div className="col-md-4">
              <label>Tipo de Acesso</label>
              <input type="text" className="form-control" value={role?.tipoAcesso} readOnly style={{ backgroundColor: 'LightGrey' }} />
            </div>
          </div>

          {role?.descricao === 'Matriz' && (
            <div style={{ display: 'flex', marginTop: '10px' }}>
              <div>
                <h6>Matrizes Disponíveis</h6>
                <select
                  multiple
                  value={selectedToMoveMatrizes.map((m) => m.id!.toString())}
                  onChange={(e) => {
                    const options = e.target.options;
                    const selected = [];
                    for (let i = 0; i < options.length; i++) {
                      if (options[i].selected) {
                        const matriz = availableMatrizes.find(m => m.id!.toString() === options[i].value);
                        if (matriz) {
                          selected.push(matriz);
                        }
                      }
                    }
                    setSelectedToMoveMatrizes(selected);
                  }}
                  style={{ width: '575px', height: '250px' }}
                >
                  {availableMatrizes.map((matriz) => (
                    <option key={matriz.id} value={matriz.id!.toString()}>
                      {matriz.id} - {matriz.nome!}
                    </option>
                  ))}
                </select>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <button type="button" onClick={moveToSelectedMatrizes} style={{ margin: '5px' }}>{'>'}</button>
                <button type="button" onClick={moveAllToSelectedMatrizes} style={{ margin: '5px' }}>{'>>'}</button>
                <button type="button" onClick={moveToAvailableMatrizes} style={{ margin: '5px' }}>{'<'}</button>
                <button type="button" onClick={moveAllToAvailableMatrizes} style={{ margin: '5px' }}>{'<<'}</button>
              </div>
              <div>
                <h6>Matrizes Selecionadas</h6>
                <select
                  multiple
                  value={selectedFromSelectedMatrizes.map((p) => p.id!.toString())}
                  onChange={(e) => {
                    const options = e.target.options;
                    const selected = [];
                    for (let i = 0; i < options.length; i++) {
                      if (options[i].selected) {
                        const matriz = selectedMatrizes.find(p => p.id!.toString() === options[i].value);
                        if (matriz) {
                          selected.push(matriz);
                        }
                      }
                    }
                    setSelectedFromSelectedMatrizes(selected);
                  }}
                  style={{ width: '575px', height: '250px' }}
                >
                  {selectedMatrizes.map((matriz) => (
                    <option key={matriz.id} value={matriz.id!.toString()}>
                      {matriz.id} - {matriz.nome!}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}

          {tipo === 'alteracao' ? (
            <div className="row">
              <div className="col-xl">
                <label>Login</label>
                <input type="text" className="form-control" value={login} readOnly style={{ backgroundColor: 'LightGrey' }} />
              </div>
              <div className="col-xl">
                <label>Senha </label>
                <input type="password" className="form-control" value={senha} onChange={(e) => setSenha(e.target.value)} />
              </div>
              <div className="col-xl">
                <label>Confirmação Senha</label>
                <input type="password" className="form-control" value={senha2} onChange={(e) => setSenha2(e.target.value)} />
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-xl">
                <label>Login <span style={{ color: 'red' }}>*</span></label>
                <input required type="text" className="form-control" value={login} onChange={(e) => setLogin(e.target.value)} />
              </div>
              <div className="col-xl">
                <label>Senha <span style={{ color: 'red' }}>*</span></label>
                <input required type="password" className="form-control" value={senha} onChange={(e) => setSenha(e.target.value)} />
              </div>
              <div className="col-xl">
                <label>Confirmação Senha <span style={{ color: 'red' }}>*</span></label>
                <input required type="password" className="form-control" value={senha2} onChange={(e) => setSenha2(e.target.value)} />
              </div>
            </div>
          )}

          <div>
            <hr className="my-4" />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <button className="w-40 btn btn-primary btn-lg btn btn-success" type="submit">{tipo !== 'inclusao' ? 'Alterar Usuário' : 'Salvar Usuário'}</button>
              </div>
            </div>
          </div>
        </form>
      )
      }
    </div >
  );
}

export default UsuarioInclusaoAlteracao;